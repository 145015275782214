@use '../../sass/variables' as *;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
}

.links-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  justify-content: flex-end;
  padding: 1.5%;

  .link {
    font-size: 1.5em;
    padding: 0 1.5%;
    color: $primary-dark-color;
    transition: transform 350ms, text-decoration 600ms;
  }

  .link-dark {
    font-size: 1.5em;
    padding: 0 1.5%;
    color: #ffecd1;
    transition: transform 350ms, text-decoration 600ms;
  }

  .link:hover,
  .link-dark:hover {
    text-decoration: underline;
    transform: scale(1.1, 1.1);
  }

  .link.active {
    text-decoration: underline $primary-dark-color;
    transform: scale(1.1, 1.1);
  }

  .link-dark.active {
    text-decoration: underline #ffecd1;
    transform: scale(1.1, 1.1);
  }
}


@media screen and (max-width: 1000px) {
  .header {
    height: initial;
  }

  .links-container {
    margin: 0 auto;
    justify-content: center;
    overflow: auto;

    .link,
    .link-dark {
      font-size: 1.25em;
      margin: 2px 5px;
    }
  }
}

@media screen and (max-width: 674px) {
  .links-container {
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;
    font-size: .8rem;
  }
}