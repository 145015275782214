@use '../../sass/variables.scss' as *;

.chat {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.chat-header{
  font-size: 2em;
  font-weight: 400;
  text-align: center;
}


.chat-text,
.faq-text,
.convo-text {
  display: flex;
  flex-flow: column wrap;
  align-items:center;
  font-size: 1.1rem;
  width: 90%;
  padding: 0% 10%;
  line-height: 1.6;
  margin: 2% 0;
  border-radius: 10px;
}

.chat-text{
  border: none;
  p{
    margin: 1%;
  }
}

.faq-text {
  h2 {
    text-align: center;
    font-size: 1.25em;
    font-weight: 400;
    margin-bottom: 1%;
    padding: 0 3%;
    text-decoration: underline;
  }

  h3 {
    text-align: center;
    font-weight: 400;
  }

  p {
    margin-bottom: 1.5%;
  }
}

.schedule-btn,
.schedule-btn-dark {
  font-size: 1.55rem;
  color: $primary-dark-color;
  background: none;
  border-radius: 4px;
  margin-top: 15px;
  padding: 3px 5px;
  transition-duration: 350ms;
}

.schedule-btn {
  color: $primary-dark-color;
  border: 2px solid $primary-dark-color;
  font-weight: 300;

  &:hover {
    cursor: pointer;
    background: $primary-dark-color;
    color: #ffecd1;
    transform: scale(1.05)
  }
}

.schedule-btn-dark {
  color: #ffecd1;
  border: 2px solid #ffecd1;
  font-weight: 300;

  &:hover {
    cursor: pointer;
    background: #ffecd1;
    color: $primary-dark-color;
    transform: scale(1.05)
  }
}

.convo-text {
  h2 {
    text-align: center;
    font-size: 1.25em;
    font-weight: 400;
    margin-bottom: 1%;
    padding: 0 3%;
    text-decoration: underline;
  }

  h3 {
    text-align: center;
    font-size: 1.25rem;
    font-style: italic;
    margin-bottom: 1%;
  }

  h4{
    font-weight: 400;
    font-size: 1.55rem;
    text-align: center;
  }
  li{
    margin-bottom: 2%;
  }
}

@media screen and (max-width: 1000px){
  .chat-header{
    font-size: 1.35rem;
  }

  .chat-text{
    p{
      text-indent: 0px;
    }
  }
  .chat-text, .faq-text,.convo-text{
    font-size: 1rem;
  }

  .convo-text{
    line-height: 1.25;
    h3{
      font-size: 1rem;
      line-height:1;
      text-align:center;
      margin-bottom: 2%;
    }
    h4{
      font-size: 1.15rem;
      margin: 2.5% 0;
    }
  }

  .schedule-btn, .schedule-btn-dark{
    font-size: 1.25rem;
  }


}