@use '../../sass/variables' as *;

.homepage {
  height: 75vh;
}

.info-container {
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.intro {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  align-self: center;
  flex-wrap: wrap;
  justify-content: space-around;
  line-height: 1.2;
  margin: 10px 0;

  .regan {
    font-size: 2.325em;
  }

  .pronounce {
    font-size: 1.35rem;
    font-style: italic;
    margin-bottom: 3px;
  }

  .brief {
    font-size: 1.45rem;
    line-height: 1.5;
    &--other{
      font-size: 1.25rem;
      font-style:italic;
    }
  }
}

.icon-container {
  width: fit-content;
  margin-top: 10px;
}

.icon {
  height: 2.45rem;
  width: 2.45rem;
  color: $primary-dark-color;
  margin: 0px 7px;
  transition-duration: 350ms;

  &:hover {
    transform: scale(1.1);
  }
}

.icon-dark {
  height: 2.45rem;
  width: 2.45rem;
  color: $primary-dark-color;
  margin: 0px 7px;
  color: #ffecd1;
  transition-duration: 350ms;

  &:hover {
    transform: scale(1.1);
  }
}

.resume {
  font-size: 1.65rem;
  color: $primary-dark-color;
  position: relative;
  background: none;
  border: 2px solid black;
  border-radius: 4px;
  bottom: 15%;
  margin: 0px 7px;
  padding: 3px 5px;
  transition-duration: 350ms;

  &:hover {
    cursor: pointer;
    background: $primary-dark-color;
    color: #ffecd1;
  }
}

.resume-dark {
  color: #ffecd1;
  font-size: 1.65rem;
  background: none;
  border: 2px solid #ffecd1;
  border-radius: 4px;
  position: relative;
  bottom: 15%;
  margin: 0px 7px;
  padding: 3px 5px;
  transition-duration: 350ms;

  &:hover {
    cursor: pointer;
    background: #ffecd1;
    color: $primary-dark-color;
  }
}



@keyframes blink-animation {
  50% {
    visibility: hidden;
  }
}

@media screen and (max-width: 1000px) {
  .homepage {
    height: 73vh;
  }

  .intro {
    width: 75%;
    margin: 0.5% 3%;

    .regan {
      font-size: 1.75rem;
      font-weight: 400;
    }

    .pronounce {
      font-size: 1.125rem;
    }

    .brief {
      font-size: 1.195rem;
    }
  }

  .icon,
  .icon-dark {
    height: 1.9rem;
    width: 1.9rem;
  }

  .resume,
  .resume-dark {
    font-size: 1.35rem;
  }
}
