@use '../../sass/variables' as *;

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.6;

  h1 {
    font-size: 2em;
    font-weight: 400;
    text-align: center;
    margin: 15px 0;
  }

  p {
    text-align: justify;
    padding: 0 10%;
  }

}


.skills-top-text {
  font-weight: 400;
  text-align: center;
  font-size: 1.3em;
}

.intro-text,
.bio-text,
.bio-text-dark {
  width: 90%;
  font-size: 1.1rem;
  padding: 0% 1.5% 2%;
  border-radius: 13px;
}


.skills-container {
  font-size: 1.325rem;
  line-height: 1.6;
  padding: 1.5% 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.langs-container,
.langs-container-dark {
  width: 60%;
  padding: 2.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.25em;
  background-color: $primary-dark-color;
  border-radius: 10px;
  color: $primary-light-color;
}

.langs-container-dark {
  background-color: #ffecd1;
  color: $primary-dark-color;
}

.fe-container,
.be-container,
.test-container,
.tools-container {
  font-size: 1.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  margin-bottom: 1%;
  flex-wrap: wrap;
  line-height: 1.2;
}

.fe-icon,
.be-icon,
.test-icon,
.tool-icon {
  font-size: 1.25rem;
  margin: 0 15px 0 5px;
}

@media screen and (max-width: 1000px) {
  .about {
    h1 {
      font-size: 1.65em;
    }

    .intro-text,
    .bio-text,
    .bio-text-dark,
    .skills-top-text {
      font-size: 1rem;
    }

    .langs-container,
    .langs-container-dark {
      font-size: 1em;
      width: 100%;

      .fe-container,
      .be-container,
      .test-container,
      .tools-container {
        width: 100%;
        font-size: .9rem;
      }

      .fe-icon,
      .be-icon,
      .test-icon,
      .tools-icon {
        font-size: .9rem;
      }
    }
  }
}