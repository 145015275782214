@use '../../sass/variables' as *;

.contact {
  height: 75vh;
  padding: 3%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  line-height: 1.3;

  h1 {
    font-size: 2em;
    font-weight: 400;
    text-align: center;

  }
}

.contact-top-text {
  width: 75%;
  font-size: 1.65rem;
}

.contact-text {
  font-size: 1.575rem;
  margin: 3% 0 2% 0;

}

.icon-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  width: 15%;
  justify-content: space-evenly;
}

.contact-icon {
  height: 2.25em;
  width: 2.25em;
  text-decoration: none;
  color: $primary-dark-color;
  transition: 350ms ease;

  &:hover {
    transform: scale(1.1);
  }
}

.contact-icon-dark {
  height: 2.25em;
  width: 2.25em;
  text-decoration: none;
  color: #ffecd1;
  transition: 350ms ease;

  &:hover {
    transform: scale(1.1);
  }
}


@media screen and (max-width:1000px) {
  .contact {
    h1 {
      font-size: 1.65rem;
    }

    .contact-top-text {
      width: initial;
      font-size: 1.235rem;
    }
  }

  .contact-text {
    font-size: 1.25rem;
    margin: 3% 0 2% 0;
  }

  .contact-icon,
  .contact-icon-dark {
    height: 2em;
    width: 2em;
    margin: 10px;
  }

}