@use '../../sass/variables' as *;
.error-page{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  line-height: 1.6;
  text-align: center;
  padding: 5%;

  h1{
    font-size: 1.75rem;
  }

  h2{
    font-size: 1.35rem;
  }
}

.return-btn{
  font-size: 1.25rem;
  position: relative;
  background: none;
  border: 2px solid black;
  border-radius: 4px;
  bottom: 15%;
  margin: 10px 7px;
  padding: 3px 5px;
  box-shadow: 4px 3px 1px black;
  transition-duration: 350ms;

  &:hover {
    cursor: pointer;
    background: $primary-dark-color;
    color: #ffecd1;
  }
}

@media screen and (max-width: 1000px) {
  .error-page{
    h1 {
      font-size: 1.45rem;
    }
    h2{
      font-size: 1.15rem;
    }

    .return-btn{
      font-size:1.125rem;
    }
  }
}










