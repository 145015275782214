@use '../../sass/variables' as *;

.projects {
  display: flex;
  flex-direction: column;
  
  h1{
    font-size: 2em;
    font-weight: 400;
    text-align: center;
    margin: 15px 0;
  
}
}


.project-container {
  display: flex;
  justify-content: space-around;
  align-content: center;
  padding: 1.5%;

  .project-img {
    border-radius: 5px;
    height: auto;
    width: 50%;
    filter: drop-shadow(5px 5px 3px #b7a283);
  }

  .project-img-dark {
    border-radius: 5px;
    height: auto;
    width: 50%;
    filter: drop-shadow(5px 5px 3px #19304c);
  }
}

.project-text {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 50%;
  flex-direction: column;
  align-items: center;
  font-size: 1.1rem;
  line-height: 1.3;
  padding: 15px;
  background-color: $primary-light-color;
  border: 2.5px solid $primary-dark-color;
  border-radius: 13px;
  margin-left: 1%;
}

.project-text-dark {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 50%;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.3;
  padding: 15px;
  background-color: #0c1724;
  border: 2.5px solid #ffecd1;
  border-radius: 13px;
  margin-left: 1%;

}


.project-name {
  font-size: 1.7rem;
  font-style: bold;
  font-weight: 500;
}

.project-links {
  font-size: 1.5rem;
  font-weight: 400;

  .site-link {
    color: $primary-dark-color;
    transition: text-decoration ease 500ms;

    &:hover {
      text-decoration: underline $primary-dark-color;

    }
  }

  .site-link-dark {
    color: #ffecd1;
    transition: text-decoration ease 500ms;

    &:hover {
      text-decoration: underline #ffecd1;

    }
  }
}

.project-text>p,
.project-text-dark>p {
  margin: 1%;
}

.tech {
  font-weight: 400;
  font-style: italic
}



@media screen and (max-width: 1000px) {
  h1 {
    font-size: 2rem;
  }

  .project-container {
    flex-direction: column;
    align-items: center;

    p {
      margin: 1%;
    }

    .project-img,
    .project-img-dark {
      height: auto;
      width: 95%;
      margin-top: 1.5%;
    }


  }

  .project-text,
  .project-text-dark {
    width: 95vw;
    font-size: .95rem;
    align-items: center;
    justify-content: space-evenly;
    margin: 5%;
  }

  .project-name {
    margin: 1.5% 0;
    font-size: 1.55rem;
  }

  .project-links {
    font-size: 1.1rem;
  }
}