@use './sass/variables' as *;
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;1,200;1,300;1,400&display=swap');

* {
  box-sizing: border-box;
  text-decoration: none;
}


body {
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  min-height: 100vh;
  width: 100%;
  background-color: $primary-light-color;
}

.App {
  min-height: 100vh;
  min-width: 100%;
  color: $primary-dark-color;
}

.top-img-container {
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;


  .blinker {
    height: 45px;
    margin-left: 5px;
    animation: blink-animation 1.5s step-end infinite;
  }

  .bulb {
    height: 35px;
    width: auto;
    margin: 5px 1% 0px 0px;
    cursor: pointer;
    transition: transform 300ms;
  }

  .bulb:hover {
    transform: scale(1.15, 1.15)
  }
}

@media screen and (max-width: 1000px) {
  .top-img-container {
    height: fit-content;

    .bulb {
      height: 1.8rem;
    }

    .blinker {
      height: 1.8rem;
    }

  }

}